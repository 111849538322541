import React, { lazy, PropsWithChildren, useMemo } from "react";
import { PageContextProvider } from "./usePageContext";
import type { PageContext } from "vike/types";
import { MainLayout } from "@root/components/layout/MainLayout";
import { AccountContext, AccountStore } from "@root/stores/accountStore";

const AccountSuspendedModal = lazy(() =>
  import("@components/modals/AccountSuspendedModal").then((module) => ({
    default: module.AccountSuspendedModal,
  }))
);

const AuthModal = lazy(() =>
  import("@components/modals/AuthModal").then((module) => ({
    default: module.AuthModal,
  }))
);
const SwitchProfileModal = lazy(() =>
  import("@components/modals/SwitchProfileModal").then((module) => ({
    default: module.SwitchProfileModal,
  }))
);

type PageLayoutProps = PropsWithChildren & {
  pageContext: PageContext;
};

export const PageLayout = ({ children, pageContext }: PageLayoutProps) => {
  const { account } = pageContext;
  const accountStore = useMemo(() => new AccountStore(account), []);

  return (
    <PageContextProvider pageContext={pageContext}>
      <AccountContext.Provider value={accountStore}>
        <React.Suspense>
          {accountStore && (
            <MainLayout store={accountStore}>
              {children}
              {account?.blockDate && (
                <AccountSuspendedModal accountStore={accountStore} />
              )}
              <React.Suspense>
                <AuthModal />
              </React.Suspense>
              {accountStore?.switchProfileModalStore && (
                <React.Suspense>
                  <SwitchProfileModal
                    store={accountStore.switchProfileModalStore}
                  />
                </React.Suspense>
              )}
            </MainLayout>
          )}
        </React.Suspense>
      </AccountContext.Provider>
    </PageContextProvider>
  );
};
