// https://vike.dev/onRenderClient
// Environment: browser

export { onRenderClient };

import ReactDOM from "react-dom/client";
import { PageLayout } from "./PageLayout";
import { getPageTitle } from "./getPageTitle";
import type { OnRenderClientAsync } from "vike/types";
import { App } from "./App";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import { MainTheme } from "@root/utils/themes";
import CssBaseline from "@mui/material/CssBaseline";
import createEmotionCache from "@root/utils/createEmotionCache";
import "@css/style.css";

const cache = createEmotionCache();

let root: ReactDOM.Root;
const onRenderClient: OnRenderClientAsync = async (
  pageContext
): ReturnType<OnRenderClientAsync> => {
  const { Page } = pageContext;

  if (!Page)
    throw new Error(
      "My onRenderClient() hook expects pageContext.Page to be defined"
    );

  const container = document.getElementById("react-root");
  if (!container) throw new Error("DOM element #react-root not found");

  document.title = getPageTitle(pageContext);

  const app = (
    <CacheProvider value={cache}>
      <ThemeProvider theme={MainTheme}>
        <CssBaseline />
        <App>
          <PageLayout pageContext={pageContext}>
            <Page />
          </PageLayout>
        </App>
      </ThemeProvider>
    </CacheProvider>
  );

  if (pageContext.isHydration) {
    try {
      root = ReactDOM.hydrateRoot(container, app);
    } catch (error) {
      console.error("Hydration failed, falling back to client render:", error);
      root = ReactDOM.createRoot(container);
      root.render(app);
    }
  } else {
    if (!root) {
      root = ReactDOM.createRoot(container);
    }
    root.render(app);
  }
};
