import { makeAutoObservable } from "mobx";

export class ModalStore {
  isOpen: boolean = false;
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  toggleLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}