import { ApiDataResponse, ApiResponse } from "@root/utils/dtos";
import { ContentType } from "@root/utils/enums";
import { pathJoin } from "@root/utils/functions";
import fetch from "cross-fetch";

export class ApiTransport {
  sessionCookie?: string;

  constructor(sessionCookie?: string) {
    this.sessionCookie = sessionCookie;
  }

  private async request<T>(
    route: string,
    options?: RequestInit
  ): Promise<ApiResponse> {
    try {
      const baseUrl =
        typeof window !== "undefined" ? "" : `${process.env.API_BASE_URL}`;        

      const response = await fetch(pathJoin([baseUrl, route], "/"), {
        credentials: "include",
        headers: {
          Cookie: `.AspNetCore.Identity.Application=${this.sessionCookie}`,
          "Content-Type": "application/json",
        },
        ...options,
      });

      const contentType = response.headers.get("content-type");

      if (contentType?.startsWith(ContentType.Json)) {
        return await response.json();

      } else if (contentType?.startsWith("text/calendar")) {
        return {
          ...response,
          data: await response.blob(),
          isSuccess: response.ok,
          statusCode: response.status,
          message: response.statusText,
        } as ApiDataResponse<T>;
      }

      return {
        ...response,
        isSuccess: response.ok,
        statusCode: response.status,
        message: response.statusText,
      } as ApiDataResponse<T>;
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false,
        statusCode: 503,
        message: error,
      } as ApiDataResponse<T>;
    }
  }

  public get<T>(
    url: string,
    options?: RequestInit
  ): Promise<ApiDataResponse<T>> {
    return this.request<T>(url, { ...options, method: "GET" });
  }

  public post<T>(
    url: string,
    body?: unknown | never,
    options?: RequestInit
  ): Promise<ApiDataResponse<T>> {
    return this.request<T>(url, {
      ...options,
      method: "POST",
      headers: {
        ...options?.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  }

  public postMultipart<T>(
    url: string,
    formData?: FormData,
    options?: RequestInit
  ): Promise<ApiDataResponse<T>> {
    return this.request<T>(url, {
      ...options,
      method: "POST",
      headers: {
        ...options?.headers,
      },
      body: formData,
    });
  }

  public put<T>(
    url: string,
    body: unknown | never,
    options?: RequestInit
  ): Promise<ApiDataResponse<T>> {
    return this.request<T>(url, {
      ...options,
      method: "PUT",
      headers: {
        ...options?.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  }

  public delete<T>(
    url: string,
    options?: RequestInit
  ): Promise<ApiDataResponse<T>> {
    return this.request<T>(url, {
      ...options,
      method: "DELETE",
    });
  }
}
