import React, { PropsWithChildren } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

export const App = ({ children }: PropsWithChildren) => (
  <React.StrictMode>      
      <GoogleOAuthProvider
        clientId={import.meta.env.PUBLIC_ENV__GOOGLE_CLIENT_ID}
      >          
        {children}
      </GoogleOAuthProvider>        
  </React.StrictMode>
);
