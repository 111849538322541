import { ErrorOption } from "react-hook-form";
import { ApiResponse } from "./dtos";
import { ErrorType, State } from "./types";
import moment from "moment";
import { StatusCodes } from "http-status-codes";
import { Coordinate } from "./entities";

export const isBrowser = () => typeof window !== 'undefined';

export const combineErrors = (errors?: string[], separator: string = ", ") =>
  errors?.join(separator);

export const getApiError = (response: ApiResponse): ErrorOption => {
  if (!response.isSuccess && !response.errors) {
    return {
      type: "serverError",
      message:
        response.statusCode == StatusCodes.INTERNAL_SERVER_ERROR
          ? "There was an issue processing your request."
          : response.message,
    };
  }

  return {
    type: "serverError",
    message: combineErrors(response.errors),
  };
};

export const getError = (type: ErrorType, errors: string[]): ErrorOption => ({
  type: type,
  message: combineErrors(errors),
});

export const hasErrors = (response: ApiResponse) => {
  return (
    !response.isSuccess ||
    (Boolean(response.errors) && response.errors!.length > 0)
  );
};

export const getGoogleDirectionsUrl = (from?: Coordinate, to?: Coordinate) =>
  `https://www.google.com/maps/dir/'${from ? `${from.latitude},${from.longitude}` : ''}'/'${to?.latitude},${to?.longitude}'`;

export const getGoogleMapUrl = (coord: Coordinate) =>
  `https://www.google.com/maps/search/${coord.latitude},${coord.longitude}`;

export const getEventShareUrl = (id: string) =>
  `${import.meta.env.PUBLIC_ENV__HOSTED_DOMAIN}?event=${id}`;

export const downloadFile = (filename: string, blob: Blob) => {  
  const url = window.URL.createObjectURL(blob);

  const element = document.createElement("a");
  element.style.display = "none";
  element.href = url;
  element.download = filename;
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
  window.URL.revokeObjectURL(url);
};

export const isAuthUrlPath = (urlPath: string) =>
  ["/login", "/forgot-password", "/register"].indexOf(
    urlPath?.toLocaleLowerCase()
  ) >= 0;
export const isAccountUrlPath = (urlPath: string) =>
  urlPath?.toLowerCase()?.startsWith("/account");

export const pathJoin = (parts: Array<unknown | never>, sep: string) => {
  const separator = sep || "/";
  const replace = new RegExp(separator + "{1,}", "g");
  return parts.join(separator).replace(replace, separator);
};

export const formatDate = (date?: moment.Moment | Date) => {
  if (!date) return ``;

  return moment(date).format("MMMM Do, YYYY h:mm a");
};

export const getStates = (): State[] => {
  return [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "Washington, D.C.", abbreviation: "DC" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ];
};
