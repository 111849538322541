import { Person } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { observer } from "mobx-react-lite";

type UserAvatarProps = {
  fallbackText?: string;
  imageUrl?: string;
};
export const UserAvatar = observer(
  ({ fallbackText, imageUrl }: UserAvatarProps) => {
    return (
      <Avatar
        src={imageUrl}
        slotProps={{
          img: {
            sx: { width: "2.5rem", height: "2.5rem" },
          },
        }}
        sx={(theme) => ({ backgroundColor: imageUrl ? "inherit" : theme.palette.primary.main })}
      >
        {imageUrl ? (
          <Person sx={{ width: "2.5rem", height: "2.5rem" }} />
        ) : (
          fallbackText
        )}
      </Avatar>
    );
  }
);
