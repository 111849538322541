import { Fragment, useState } from "react";
import { navigate } from "vike/client/router";
import { Person } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { useAccountContext } from "@root/stores/accountStore";
import { googleLogout } from "@react-oauth/google";
import { accountService } from "@root/services/api/accountService";
import { observer } from "mobx-react-lite";
import { Account } from "@root/utils/entities";
import { Logo } from "../common/Logo";

export const MainNavigation = observer(() => {
  const accountStore = useAccountContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    googleLogout();
    await accountService.logout();
    await navigate("/", { overwriteLastHistoryEntry: true });
    accountStore.clearAccountSession();
  };

  const routes: { text: string; href?: string; onClick?: () => void }[] = [
    {
      text: accountStore.isUserProfile ? "Organizations" : "Organization",
      href: accountStore.isUserProfile
        ? "/account/organizations"
        : `/account/organizations/${accountStore.session?.id}`,
    },
    {
      text: "My Events",
      href: "/account/events",
    },
  ];

  if (accountStore.isUserProfile) {
    routes.splice(0, 0, { text: "Profile", href: "/account" });
  }

  routes.splice(1, 0, { text: "Credits", href: "/account/credits/purchase" });

  if (accountStore.profiles.length > 1) {
    routes.splice(0, 0, {
      text: "Switch Profile",
      onClick: () => accountStore.showSwitchProfileModal(),
    });
  }

  return (
    <Fragment>
      <AppBar sx={{ padding: 0 }}>
        <Toolbar>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: "3rem",
              width: "100%",
              position: "relative",
              [theme.breakpoints.down("md")]: {
                px: "1rem",
                justifyContent: "start",
              },
            })}
          >
            <Logo />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: "1.5rem",
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              {accountStore?.account ? (
                <Fragment>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={(e) => {
                      handleMenu(e);
                    }}
                    color="inherit"
                    sx={{
                      padding: 0,
                      backgroundColor: "transparent !important",
                    }}
                  >
                    <UserAvatar account={accountStore.account!} />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {routes.map((route, index) => (
                      <MenuItem
                        key={`mainMenuItem${index}`}
                        onClick={async (e) => {
                          e.preventDefault();
                          handleClose();

                          if (route.href) {
                            await navigate(route.href);
                          } else if (route.onClick) {
                            route.onClick();
                          }
                        }}
                      >
                        {route.text}
                      </MenuItem>
                    ))}
                    <MenuItem
                      onClick={async (e) => {
                        e.preventDefault();
                        handleClose();
                        await logout();
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </Fragment>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    height: "100%",
                  }}
                >
                  <Button
                    variant="text"
                    type="button"
                    onClick={() => accountStore.authModalStore.openLoginModal()}
                  >
                    Login
                  </Button>
                  <Button
                    type="button"
                    onClick={() =>
                      accountStore.authModalStore.openAuthModal("register")
                    }
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    Sign Up
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Fragment>
  );
});

type UserAvatarProps = {
  account: Account;
};

const UserAvatar = observer(({ account }: UserAvatarProps) => (
  <Avatar
    src={account.imageUrl || undefined}
    sx={{ width: "100%", height: "100%" }}
    slotProps={{
      img: {
        sx: { width: "2.5rem", height: "2.5rem" },
      },
    }}
  >
    {!account.imageUrl && <Person sx={{ width: "2.5rem", height: "2.5rem" }} />}
  </Avatar>
));
