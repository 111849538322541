import logoTextUrl from "@images/logo-text.png";

export const Logo = () => {
  return (
    <div>
      <a onClick={() => location.href = "/"} style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={logoTextUrl}
          alt="EventPin logo"
          style={{ height: "3rem", width: "auto" }}
          loading="lazy"
        />
      </a>
    </div>
  );
};
