import { Box, CircularProgress } from "@mui/material";

type LoadingSpinnerProps = {
  size?: number | string;
};

export const LoadingSpinner = ({ size }: LoadingSpinnerProps) => (
  <Box sx={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
    <CircularProgress size={size} />
  </Box>
);
