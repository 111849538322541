/* eslint-disable @typescript-eslint/no-explicit-any */
import { createTheme } from "@mui/material/styles";

export const MainTheme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    primary: {
      main: "#CC0000",
    },
    info: {
      main: "#0253CC",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }: any) => ({
          background: "#FFF",
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: "0 !important",
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: ({ theme }: any) => ({
          [theme.breakpoints.down("sm")]: {
            border: 0,
          },
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }: any) => ({
          padding: "3rem",
          [theme.breakpoints.down("sm")]: {
            padding: "1rem",
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }: any) => ({
          color: "#fff",
          zIndex: theme.zIndex.drawer + 1,
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        type: "submit",
      },
      styleOverrides: {
        root: ({ theme, ownerState }: any) => ({
          fontWeight: 500,
          fontSize: "1rem",
          lineHeight: "1.5rem",
          [theme.breakpoints.down("sm")]: {
            fontSize: ".825rem",
          },
          ...(ownerState.variant === "text" && {
            color: theme.palette.primary.main,
          }),
          ...(ownerState.variant === "contained" && {
            backgroundColor: theme.palette.primary.main,
            color: "#FFF",
            textTransform: "none",
            boxSizing: "border-box",
            borderRadius: "0.5rem",
            padding: "0.5rem 1rem",
          }),
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        variant: "body2",
      },
      styleOverrides: {
        root: ({ ownerState }: any) => ({
          cursor: "pointer",
          ...(ownerState.variant === "body2" && {
            color: "#000000",
            fontWeight: 700,
            fontSize: "1rem",
            lineHeight: "1.25rem",
          }),
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: "body2",
      },
      styleOverrides: {
        root: ({ theme, ownerState }: any) => ({
          fontWeight: 400,
          color: "#000000",

          ...(ownerState.variant === "body2" && {
            fontSize: "1rem",
            lineHeight: "1.25rem",
          }),
          ...(ownerState.variant === "h2" && {
            fontSize: "1.5rem",
            lineHeight: "2.25rem",
            fontWeight: 700,
          }),
          ...(ownerState.variant === "h5" && {
            [theme.breakpoints.down("sm")]: {
              fontSize: "1.25rem",
            },
          }),
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          ":last-child td": {
            borderBottom: 0,
          },
        },
      },
    },
  },
});
