export enum VerificationMethod {
  Email = 1,
  Phone = 2,
}

export enum SessionState {
  Authenticated = "Authenticated",
  Unauthenticated = "Unauthenticated",
}

export enum ContentType {
  Json = "application/json",
  Text = "text/plain",
}

export enum OAuthProvider {
  Google = "google",
  Facebook = "facebook",
  Apple = "apple",
}

export enum Timeout {
  SuccessMessage = 2500
}

export enum EventSearchDefaults {
  MileRadius = 50,
  MinAge = 0,
  MaxAge = 65
}