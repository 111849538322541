import { Backdrop } from "@mui/material";
import { LoadingSpinner } from "./LoadingSpinner";

type LoadingOverlayProps = {
  show: boolean | (() => boolean);
};

export const LoadingOverlay = ({ show }: LoadingOverlayProps) => (
  <Backdrop open={typeof show === "boolean" ? show : show()}>
    <LoadingSpinner />
  </Backdrop>
);
