import { Fragment, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import {
  AddShoppingCart,
  Bookmark,
  Business,
  Close,
  Edit,
  Event,
  Password,
  People,
  Person,
  Search,
} from "@mui/icons-material";
import { useAccountContext } from "@root/stores/accountStore";
import { Logo } from "../common/Logo";
import { navigate } from "vike/client/router";
import { LoadingOverlay } from "../common/LoadingOverlay";

type MenuDrawerProps = {
  open: boolean;
  toggleDrawer: (open: boolean) => void;
};
export const MenuDrawer = observer(
  ({ open, toggleDrawer }: MenuDrawerProps) => {
    const accountStore = useAccountContext();
    const [isNavigating, setIsNavigating] = useState(false);

    const userRoutes: Route[] = [
      { text: "Profile", href: "/account", icon: () => <Person /> },
      // { text: "Events I'm Attending", href: "/account/events" },  FIXME
      // { text: "Events I Follow", href: "/account/events" },  FIXME
      { text: "My Events", href: "/account/events", icon: () => <Event /> },
      {
        text: "Credits",
        href: "/account/credits/purchase",
        icon: () => <AddShoppingCart />,
      },
      {
        text: "Organizations",
        href: "/account/organizations",
        icon: () => <Business />,
      },
      {
        text: "Password",
        href: "/account/change-password",
        icon: () => <Password />,
      },
    ];

    const orgRoutes: Route[] = [
      {
        text: "Organization",
        href: `/account/organizations/${accountStore.session?.id}`,
        icon: () => <Edit />,
      },
      { text: "My Events", href: "/account/events", icon: () => <Event /> },
      {
        text: "Credits",
        href: "/account/credits/purchase",
        icon: () => <AddShoppingCart />,
      },
      {
        text: "Users",
        href: `/account/organizations/${accountStore.session?.id}/users`,
        icon: () => <People />,
      },
      {
        text: "Followers",
        href: `/account/organizations/${accountStore.session?.id}/followers`,
        icon: () => <Bookmark />,
      },
    ];

    const routes = accountStore.isUserProfile ? userRoutes : orgRoutes;

    return (
      <Fragment>
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              padding: "1rem 0",
              minWidth: "24rem",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "1rem",
                  padding: "0 1rem",
                }}
              >
                <Logo />
                <IconButton onClick={() => toggleDrawer(false)}>
                  <Close />
                </IconButton>
              </Box>
              <List sx={{ paddingBottom: 0 }}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={async () => {
                      setIsNavigating(true);
                      toggleDrawer(false);
                      await navigate("/");
                      setIsNavigating(false);
                    }}
                  >
                    <ListItemIcon>
                      <Search />
                    </ListItemIcon>
                    <ListItemText primary="Discover Events" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
            <Divider />
            <List>
              {routes.map((route) => (
                <ListItem key={route.href} disablePadding>
                  <ListItemButton
                    onClick={async () => {
                      if (route.onClick) {
                        setIsNavigating(true);
                        toggleDrawer(false);
                        route.onClick();
                        setIsNavigating(false);
                      } else {
                        setIsNavigating(true);
                        toggleDrawer(false);
                        await navigate(route.href!);
                        setIsNavigating(false);
                      }
                    }}
                  >
                    <ListItemIcon>{route.icon()}</ListItemIcon>
                    <ListItemText primary={route.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </SwipeableDrawer>
        <LoadingOverlay show={isNavigating} />
      </Fragment>
    );
  }
);

type Route = {
  text: string;
  href?: string;
  onClick?: () => void;
  icon: () => React.ReactNode;
};
