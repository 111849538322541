import { makeAutoObservable } from "mobx";
import { ModalStore } from "./modalStore";

export class AuthModalStore {
  readonly modalStore: ModalStore;

  modalType: ModalType;
  redirectUrl?: string;

  constructor() {
    makeAutoObservable(this);
    this.modalStore = new ModalStore();
  }

  openLoginModal(redirectUrl?: string) {
    this.modalType = "login";
    this.redirectUrl = redirectUrl;
    this.modalStore.open();
  }

  openAuthModal(type: ModalType) {
    this.modalType = type;
    this.modalStore.open();
  }

  closeAuthModal() {
    this.modalStore.close();    
  }

  changeAuthModalType(type: ModalType) {
    this.modalType = type;
  }

  isAuthModalOpen() {
    return this.modalStore.isOpen;
  }
}

type ModalType =
  | "login"
  | "register"
  | "forgot-password"
  | "verification"
  | "change-password";